import {getTranslatedNumber} from "@/utils/NumberTranslate";
import {EMPTY_GUID} from "@/utils/GuidUtil";

export interface JourneyTab {
    [index: string]: any;
    id: string;
    title?: string;
    audio2audio1?: string;
    audio2?: string;
    copyrightAudio?: string;
    audio1Filename?: string;
    audio2Filename?: string;
    copyrightAudioFilename?: string;
    text1?: string;
    text2?: string;
    imageId?: string;
    tabNum: number;
    templateId: string;
    tabIconId: string;
    icon: string;
    toRemove?: boolean;
}

export function validateTab(tab: JourneyTab): JourneyTab {
    tab.audio1 = (tab.audio1) ? tab.audio1 : '';
    tab.audio2 = (tab.audio2) ? tab.audio2 : '';
    tab.copyrightAudio = (tab.copyrightAudio) ? tab.copyrightAudio : '';
    tab.audio1Filename = (tab.audio1Filename) ? tab.audio1Filename : '';
    tab.audio2Filename = (tab.audio2Filename) ? tab.audio2Filename : '';
    tab.copyrightAudioFilename = (tab.copyrightAudioFilename) ? tab.copyrightAudioFilename : '';
    return tab;
}

function isFinalTab(tabNum: number, tabsCount = 0): boolean {
    if (tabNum !== 4 && tabsCount !== 0){
        return tabNum === tabsCount;        
    }
    return tabNum === 4;
}

function isExtraTab(tabNum: number): boolean {
    return tabNum === 3;
}

function isScriptureTab(tabNum: number): boolean {
    return tabNum === 2;
}

export function getMissingTabInfo(tab: JourneyTab, index: number, language: any, tabsCount: number): string {
    let message = '';
    if (!tab.title?.trim()) {
        message += `<li>${ language.templateTabTitle } (${getTranslatedNumber(index + 1)})</li>`;
    }
    if (!tab.imageId || tab.imageId === EMPTY_GUID) {
        message += `<li>${ language.templateTabImage } (${getTranslatedNumber(index + 1)})</li>`;
    }
    if ((isExtraTab(tab.tabNum) && isFinalTab(tab.tabNum + 1)  && !tab.text1?.trim()) || (!isExtraTab(tab.tabNum) && !tab.text1?.trim()) || (isScriptureTab(tab.tabNum) && !tab.text2?.trim())) {
        message += `<li>${ language.templateTabContent } (${getTranslatedNumber(index + 1)})</li>`;
    }
    if ((isExtraTab(tab.tabNum) && isFinalTab(tab.tabNum + 1)  && !tab.audio1?.trim()) || (!isExtraTab(tab.tabNum) && !tab.audio1?.trim()) || (isScriptureTab(tab.tabNum) && !tab.audio2?.trim())) {
        message += `<li>${ language.templateTabAudio } (${getTranslatedNumber(index + 1)})</li>`;
    }
    if (isFinalTab(tab.tabNum, tabsCount) && !tab.copyrightAudio?.trim()) {
        message += `<li>${ language.templateCopyrightAudio }</li>`;
    }
    return message;
}