import {getMissingJourneyInfo, Journey} from "@/models/JourneyModel";
import {EMPTY_GUID} from "@/utils/GuidUtil";
import {getTemplate} from "@/services/TemplateService";
import {getAppInstance} from "@/services/AppInstanceService";
import {getMissingTemplateInfo} from "@/models/JourneyTemplateModel";
import {getMissingTabInfo, JourneyTab} from "@/models/JourneyTabModel";
import {store} from "@/store/Store";
import {getJourneySet} from "@/services/JourneySetService";

export interface JourneySet {
    id: string;
    title: string;
    imageId: string;
    imageData: string;
    published: boolean;
    publishedOn?: string;
    templateId: string;
    templateName: string;
    enableCustomContent: boolean;
    keyword: string;
    displayOrder: number;
    journeys: Journey[];
    journeySetTypeId: string;
    appInstanceId: string;
    updateAvailable: boolean;
    legacy: boolean;
    isPwaDefault: boolean;
    isSecureDefault: boolean;
    isAppDefault: boolean;
}

function getMissingJourneySetInfo(journeySet: JourneySet, language: any): string {
    let message = '';
    if (!journeySet.title?.trim()) {
        message += `<li>${ language.journeySetTitle }</li>`;
    }
    if (!journeySet.keyword?.trim()) {
        message += `<li>${ language.journeySetKeyword }</li>`;
    }
    if (!journeySet.imageId || journeySet.imageId === EMPTY_GUID) {
        message += `<li>${ language.journeySetImage }</li>`;
    }
    if (!journeySet.journeySetTypeId || journeySet.journeySetTypeId === EMPTY_GUID) {
        message += `<li>${ language.journeySetCategory }</li>`;
    }
    if (journeySet.journeys.length === 0) {
        message += `<li>${ language.journeys }</li>`;
    }
    return message;
}

export async function getMissingInfo(id: string, download: boolean, language: any): Promise<string> {
    const journeySet = await getJourneySet(id);
    const mJInfo: any[] = []; //array of missing journey info
    const mTInfo: any[] = []; //array of missing tab info
    let message = '';
    const template = await getTemplate(journeySet.templateId);
    const appInstance = await getAppInstance(store.getters['app/selectedAppInstanceId']);
    const tabsCount = template.tabsCount;
    const needsExtraInfo = template.tabsCount === 4;

    //Collect Missing Journey Info
    journeySet.journeys.map((journey: Journey) => {
       mJInfo.push(getMissingJourneyInfo(journey, needsExtraInfo, appInstance, language));
    });

    const mJInfoAll: string[] = [];

    mJInfo.forEach(mJInfoItem => {
        //Match each item for <li> elements
        const liItems = mJInfoItem.match(/<li>.*?<\/li>/g);
        if (liItems){
            liItems.forEach((liItem: string) => {
                //console.log(liItem);
                mJInfoAll.push(liItem);
            });
        }
    });


    //Create array of unique Missing Journey Info Items
    const uniqueMJInfo: any[] = Array.from(new Set(mJInfoAll)); //array of unique missing journey info

    //Print Missing Journey Info
    uniqueMJInfo.forEach(uniqueMJInfoItem => message += uniqueMJInfoItem);

    //Print Missing Journey Set Info
    message += getMissingJourneySetInfo(journeySet, language);

    //Print Missing Template Info
    message += getMissingTemplateInfo(template, language);

    //Collect Missing Tab Info
    template.tabs.map((tab: JourneyTab, index) => {
        mTInfo.push(getMissingTabInfo(tab, index, language, tabsCount));
    });


    //Create arrays for Missing Tab Info Items 
    //const uniqueMTInfo: any[] = Array.from(new Set(mTInfo)); //array of unique missing journey info
    const mTInfoTabs: { [key: string]: string[] } = {};
    const mTInfoNoTabs: string[] = [];

    mTInfo.forEach(mTInfoItem => {
        //Match each item for a <li> elements
        const liItems = mTInfoItem.match(/<li>.*?<\/li>/g);

        if (liItems){
            liItems.forEach((liItem: string) => {
                //console.log(liItem);

                //Match each item for a single digit inside parentheses at the end
                const match = liItem.match(/<li>(.*?)\s*\((\d)\)<\/li>/);

                if (match) {
                    const mTInfoItemMsg = match[1];
                    const mTInfoTabNumber = match[2];
                    //console.log(mTInfoItemMsg + " " + mTInfoTabNumber);

                    //Initialize the array of Tab Info Items if it doesn't exist
                    if (!mTInfoTabs[mTInfoItemMsg]) {
                        mTInfoTabs[mTInfoItemMsg] = [];
                    }

                    //Add the Tab number to the Tab Info array
                    mTInfoTabs[mTInfoItemMsg].push(mTInfoTabNumber);
                } else {
                    //Add item to mTInfoNoTabs
                    mTInfoNoTabs.push(liItem);
                }
            });
        } else {
            //Add item to MTInfoNoTabs
            mTInfoNoTabs.push(mTInfoItem);
        }
            
    });

    //Print Missing Tab Info
    for (const [missing, tabs] of Object.entries(mTInfoTabs)) {
        message += `<li>${missing} ${tabs.join(', ')}</li>`;
    }
    mTInfoNoTabs.forEach(mTInfoNoTabsItem => message += mTInfoNoTabsItem);


    if (message) {
        message = download ? 
            (language.missingInfoDownloadWarning + '<ul>' + message + '</ul>' + language.missingInfoDownloadWarningQuestion) : 
            (language.missingInfoPublishWarning + '<ul>' + message + '</ul>' + language.missingInfoPublishWarningQuestion);
    }
    
    return message;
}